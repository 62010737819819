export const FETCH_ORGS = 'FETCH_ORGS';
export const RECEIVE_ORGS = 'RECEIVE_ORGS';
export const SENDING_ORG_INVITE = 'SENDING_ORG_INVITE';
export const SEND_ORG_INVITE_ERROR = 'SEND_ORG_INVITE_ERROR';
export const SEND_ORG_INVITE_SUCCESS = 'SEND_ORG_INVITE_SUCCESS';
export const ACCEPTING_ORG_INVITE = 'ACCEPTING_ORG_INVITE';
export const ACCEPT_ORG_INVITE_SUCCESS = 'ACCEPT_ORG_INVITE_SUCCESS';
export const ACCEPT_ORG_INVITE_ERROR = 'ACCEPT_ORG_INVITE_ERROR';
export const FETCHING_ORG_ACTIVITY = 'FETCHING_ORG_ACTIVITY';
export const RECEIVE_ORG_ACTIVITY = 'RECEIVE_ORG_ACTIVITY';
export const SENDING_ORG_UPDATE = 'SENDING_ORG_UPDATE';
export const FETCHING_ORG_MEMBERS = 'FETCHING_ORG_MEMBERS';
export const RECEIVE_ORG_MEMBERS = 'RECEIVE_ORG_MEMBERS';
export const ORG_REMOVE_USER_ACCESS = 'ORG_REMOVE_USER_ACCESS';
export const ORG_REMOVE_USER_ACCESS_SUCCESS = 'ORG_REMOVE_USER_ACCESS_SUCCESS';
export const ORG_REMOVE_USER_ACCESS_ERROR = 'ORG_REMOVE_USER_ACCESS_ERROR';

export const FETCH_ORG_COMMUNITY = 'FETCH_ORG_COMMUNITY';
export const RECEIVE_ORG_COMMUNITY = 'RECEIVE_ORG_COMMUNITY';

export const START_ASSET_UPLOAD = 'START_ASSET_UPLOAD';
export const FINISH_ASSET_UPLOAD = 'FINISH_ASSET_UPLOAD';

const defaultState = {
  isLoading: null,
  orgs: {
  },
  community: {
    data: {},
    isLoading: null,
    assetUpload: [],
  },
  current: {
    inviteUser: {
      loading: false,
      error: '',
    },
    acceptStatus: {
      status: 'UNSTARTED',
      error: '',
    },
    activity: {
      isLoading: null,
      data: [],
    },
  },
};

const orgReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_ORGS:
      return {
        ...state,
        isLoading: true,
      };
    case RECEIVE_ORGS: {
      const newOrgs = { ...state.orgs };
      action.orgs.forEach((o) => {
        newOrgs[o.shortId] = o;
      });
      return {
        ...state,
        isLoading: false,
        orgs: newOrgs,
      };
    }
    case FETCH_ORG_COMMUNITY:
      return {
        ...state,
        community: {
          ...state.community,
          isLoading: true,
        },
      };
    case RECEIVE_ORG_COMMUNITY: {
      const newComs = { ...state.community.data };
      action.com.forEach((c) => {
        newComs[c._id] = c;
      });
      return {
        ...state,
        community: {
          ...state.community,
          data: newComs,
          isLoading: false,
        },
      };
    }
    case START_ASSET_UPLOAD: {
      const dedupedAssets = state.community.assetUpload.filter(
        (asset) => action.files.find((f) => f.name === asset.name) === undefined,
      );
      return {
        ...state,
        community: {
          ...state.community,
          assetUpload: [...dedupedAssets, ...action.files.map((f) => ({ ...f, status: 'pending' }))],
        },
      };
    }
    case FINISH_ASSET_UPLOAD: {
      return {
        ...state,
        community: {
          ...state.community,
          assetUpload: state.community.assetUpload.filter(
            (asset) => action.successes.find((s) => s.file.name === asset.name) === undefined,
          ),
        },
      };
    }
    case SENDING_ORG_INVITE:
      return {
        ...state,
        current: {
          ...state.current,
          inviteUser: {
            loading: true,
            error: '',
          },
        },
      };
    case SEND_ORG_INVITE_ERROR:
      return {
        ...state,
        current: {
          ...state.current,
          inviteUser: {
            loading: false,
            error: action.error,
          },
        },
      };
    case SEND_ORG_INVITE_SUCCESS: {
      const newOrgs = { ...state.orgs };
      action.orgs.forEach((o) => {
        newOrgs[o.shortId] = o;
      });
      return {
        ...state,
        isLoading: false,
        orgs: newOrgs,
        current: {
          ...state.current,
          inviteUser: {
            loading: false,
            error: '',
          },
        },
      };
    }
    case ACCEPTING_ORG_INVITE: {
      return {
        ...state,
        current: {
          ...state.current,
          acceptStatus: {
            status: 'PENDING',
            error: '',
          },
        },

      };
    }
    case ACCEPT_ORG_INVITE_SUCCESS: {
      return {
        ...state,
        isLoading: null, // re-fetch the user's org list
        current: {
          ...state.current,
          acceptStatus: {
            status: 'SUCCESS',
            error: '',
          },
        },
      };
    }
    case ACCEPT_ORG_INVITE_ERROR: {
      return {
        ...state,
        isLoading: null, // re-fetch the user's org list
        current: {
          ...state.current,
          acceptStatus: {
            status: 'ERROR',
            error: action.error,
          },
        },
      };
    }
    case FETCHING_ORG_ACTIVITY: {
      return {
        ...state,
        current: {
          ...state.current,
          activity: {
            ...state.current.activity,
            isLoading: true,
          },
        },
      };
    }
    case RECEIVE_ORG_ACTIVITY: {
      const {
        docs,
        ...otherPaging
      } = action.activiyPaging;
      return {
        ...state,
        current: {
          ...state.current,
          activity: {
            ...state.current.activity,
            isLoading: false,
            data: docs,
            ...otherPaging,
          },
        },
      };
    }
    case ORG_REMOVE_USER_ACCESS:
      return {
        ...state,
        isLoading: true,
      };
    case ORG_REMOVE_USER_ACCESS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ORG_REMOVE_USER_ACCESS_SUCCESS: {
      const newOrgs = { ...state.orgs };
      action.orgs.forEach((o) => {
        newOrgs[o.shortId] = o;
      });
      return {
        ...state,
        isLoading: false,
        orgs: newOrgs,
      };
    }
    case FETCHING_ORG_MEMBERS: {
      const newOrgs = { ...state.orgs };
      const targetOrg = { ...newOrgs[action.shortId] };
      targetOrg.memberInfoLoading = true;
      newOrgs[action.shortId] = targetOrg;
      return {
        ...state,
        orgs: newOrgs,
      };
    }
    case RECEIVE_ORG_MEMBERS: {
      const newOrgs = { ...state.orgs };
      const targetOrg = { ...newOrgs[action.shortId] };
      targetOrg.memberInfo = action.memberInfo;
      targetOrg.memberInfoLoading = false;
      newOrgs[action.shortId] = targetOrg;
      return {
        ...state,
        orgs: newOrgs,
      };
    }
    default:
      return state;
  }
};

export default orgReducer;
